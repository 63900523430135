import React, { useState } from "react";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../css/modal.css";
const OnlineApplication = () => {
  const [show, setShow] = useState(false);
  const [Mstate, setMstate] = useState({
    title: "",
    URL: "",
    ImageURL: "",
  });
  const handleClose = () => setShow(false);
  function handleShow(title, URL, ImageURL) {
    setMstate({
      title: title,
      URL: URL,
      ImageURL: ImageURL,
    });

    setShow(true);
  }
  return (
    <React.Fragment>
      <Header />
      <FixMenu />
      <section className="consumer_inner_bg">
        <img src="assets/images/apply-online-banner.jpg" alt="Online Applications"/>
        <div className="container-fluid">
          <div className="apply_title">
            <h1>Application Form</h1>
          </div>
        </div>
      </section>
      <section className="main_sectiods">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 brdcum_designs">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="https://www.noidapower.com/">Home</a>
                  <span className="icondsds">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/consumer-portal" target="_blank">
                    Consumer Portal
                  </Link>
                  <span className="icondsds">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <a href="#" className="active">
                    Application Form
                  </a>
                </li>
              </ol>
            </div>
          </div>
          <div className="row justify-content-center padtops">
            <div className="row bg_cols">
              <div className="col-sm-12 bg_cols">
                <div className="form_div">
                  <h2 className="online_heading">Application Form</h2>
                  <div className="rediobuttonstyle">
                    <form className="form">
                      <div className="plan">
                        <ul className="box_online">
                          <li>
                            <a
                              href="https://iwebapps.noidapower.com:8032/Online_applications.aspx/?appName=NSc"
                              //href="#"
                              target="_blank" rel="noreferrer"
                              // onClick={() =>
                              //   handleShow(
                              //     "Load Reduction/Load Augmentation",
                              //     "https://iwebapps.noidapower.com:8032/Authenticate/login.aspx",
                              //     "https://iwebapps.noidapower.com:8032/images/Load-reduction.jpg"
                              //   )
                              // }
                            >
                              <img src="assets/images/NSC_icon.jpg" alt="" />
                              New Service Connection
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://iwebapps.noidapower.com:8032/Online_applications.aspx/?appName=LRe"
                              //href="#"
                              target="_blank" rel="noreferrer"
                              // onClick={() =>
                              //   handleShow(
                              //     "Load Reduction/Load Augmentation",
                              //     "https://iwebapps.noidapower.com:8032/Authenticate/login.aspx",
                              //     "https://iwebapps.noidapower.com:8032/images/Load-reduction.jpg"
                              //   )
                              // }
                            >
                              <img
                                src="assets/images/load_reduction.jpg"
                                alt=""
                              />
                              Load Reduction/Load Augmentation
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://iwebapps.noidapower.com:8032/Online_applications.aspx/?appName=NMu"
                              target="_blank" rel="noreferrer"
                              // onClick={() =>
                              //   handleShow(
                              //     "Name Mutation",
                              //     "https://iwebapps.noidapower.com:8032/nameMutation.aspx",
                              //     "https://iwebapps.noidapower.com:8032/images/Name-Mutation.jpg"
                              //   )
                              // }
                            >
                              <img
                                src="assets/images/name-mutation.jpg"
                                alt=""
                              />
                              Name Mutation
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://iwebapps.noidapower.com:8032/Online_applications.aspx/?appName=CCh"
                              target="_blank" rel="noreferrer"
                              // onClick={() =>
                              //   handleShow(
                              //     "Category Change",
                              //     "https://iwebapps.noidapower.com:8032/Authenticate/login.aspx",
                              //     "https://iwebapps.noidapower.com:8032/images/Category-Change.jpg"
                              //   )
                              // }
                            >
                              <img
                                src="assets/images/category-change.jpg"
                                alt=""
                              />
                              Category Change
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://iwebapps.noidapower.com:8032/download/PD_SD_FORM.pdf"
                              target="_blank" rel="noreferrer"
                            >
                              <img
                                src="assets/images/permanent-disconnection.jpg"
                                alt=""
                              />
                              Permanent Disconnection & Security Refund
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://iwebapps.noidapower.com:8032/download/name_&_address_correction.pdf"
                              target="_blank" rel="noreferrer"
                            >
                              <img
                                src="assets/images/name-address-correction.jpg"
                                alt="" 
                              />
                              Name and Address Correction
                            </a>
                          </li>

                          <li>
                            <a
                              href="https://iwebapps.noidapower.com:8032/MTRCHPMT/MeterTesting_Reg.aspx"
                              target="_blank" rel="noreferrer"
                            >
                              <img src="assets/images/amt.jpg" alt="" />
                              Meter Testing
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://iwebapps.noidapower.com:8032/download/not%20in%20use%20declaration.pdf"
                              target="_blank" rel="noreferrer"
                            >
                              <img src="assets/images/not-in-use.jpg" alt="" />
                              Not In Use Declaration
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://iwebapps.noidapower.com:8032/download/Shifting_of_Service_mains_form.pdf"
                              target="_blank" rel="noreferrer"
                            >
                              <img
                                src="assets/images/shiftingOFMeter.jpg"
                                alt=""
                              />
                              Shifting of Service / Meter
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://iwebapps.noidapower.com:8032/Online_KYCUpdate.aspx"
                              target="_blank" rel="noreferrer"
                            >
                              <img src="assets/images/KYCUpdate.jpg" alt="KYC UPDATE" />
                              KYC Update
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://iwebapps.noidapower.com:8032/Appointment_Based_Service.aspx"
                              target="_blank" rel="noreferrer"
                            >
                              <img src="assets/images/ABS.jpg" alt="Appointment based services" />
                              Appointment Based Services
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://iwebapps.noidapower.com:8032/Authenticate/login.aspx"
                              target="_blank" rel="noreferrer"
                            >
                              <img src="assets/images/isolation.jpg" alt="" />
                              Apply for Isolation / Revocation
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://iwebapps.noidapower.com:8032/OnlineDocumentUpload.aspx"
                              target="_blank" rel="noreferrer"
                            >
                              <img
                                src="assets/images/documentSubmission.jpg"
                                alt=""
                              />
                              Document Submission
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://iwebapps.noidapower.com:8032/NotificationStatus.aspx"
                              target="_blank" rel="noreferrer"
                            >
                              <img
                                src="assets/images/notificationStatus.jpg"
                                alt=""
                              />
                              Application Status Check
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://iwebapps.noidapower.com:8032/CE_Know_Your_BP.aspx"
                              target="_blank" rel="noreferrer"
                            >
                              <img
                                src="assets/images/knowBPnumber.jpg"
                                alt=""
                              />
                              Know Your Consumer Number
                            </a>
                          </li>
                        </ul>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{Mstate.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={Mstate.ImageURL} alt="" className="img-responsive " />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">
            <a
              href={Mstate.URL}
              target="_blank" rel="noreferrer"
              style={{ color: "#fff", textDecoration: "none" }}
            >
              Apply
            </a>
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default OnlineApplication;
