import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  function openFuncation() {
    var element = document.getElementById("search-wrap");
    element.classList.add("searchactive");
  }
  function closeFuncation() {
    var element = document.getElementById("search-wrap");
    element.classList.remove("searchactive");
  }
  function menuopenFuncation() {
    var element = document.getElementById("fixmnu");
    element.classList.add("active");

    var element = document.getElementById("bodyaddid");
    element.classList.add("scrollstop");
  }

  window.onscroll = function () {
    headerFunction();
  };

  function headerFunction() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.getElementById("headerbottom").className =
        "headerbottom designtime";
      document.getElementById("navbar-collapse").className =
        "navbar-collapse mobilemenu designtime";
      document.getElementById("navbar-collapse1").className =
        "collapse navbar-collapse designtime";
    } else {
      document.getElementById("headerbottom").className = "headerbottom";
      document.getElementById("navbar-collapse").className =
        "navbar-collapse mobilemenu";
      document.getElementById("navbar-collapse1").className =
        "collapse navbar-collapse";
    }
  }
  function show1() {
    document.getElementById("div1").style.display = "none";
  }
  function show2() {
    document.getElementById("div1").style.display = "block";
  }
  function otherlink() {
    document.getElementById("div1").style.display = "none";
  }
  function show2() {
    document.getElementById("div1").style.display = "block";
  }
  return (
    <React.Fragment>
      <header>
        <div className="headerbottom" id="headerbottom">
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <a className="navbar-brand" href="/Home">
                <img src="assets/img/logo.png" alt="NPCL" />
              </a>
              <div className="navbar-collapse mobilemenu" id="navbar-collapse">
                <li className="loginclick">
                  <a
                    href="https://iwebapps.noidapower.com:8032/Authenticate/login.aspx"
                    className=""
                    target="_blank"  rel="noreferrer"
                  >
                    Sign In/ Register
                  </a>
                </li>
                {/* <div
                  className="searchicon25 searchopen"
                  onClick={openFuncation}
                >
                  <i className="fa fa-search"></i>
                </div> */}
                <div
                  className="wrapper-menu togglemnu"
                  onClick={menuopenFuncation}
                >
                  <div className="line-menu half start"></div>
                  <div className="line-menu"></div>
                  <div className="line-menu half end"></div>
                </div>
              </div>
              <div className="collapse navbar-collapse" id="navbar-collapse1">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 w-100 num-top">
                <li>
                    <i className="fa fa-phone"></i>&nbsp;&nbsp;Emergency:
                    <strong>  9718722222 </strong> &nbsp;|
                  </li>
                  <li>
                    <span>24X7</span>&nbsp;&nbsp; Call Center: <strong> 0120 6226666</strong>  &nbsp;| &nbsp;
                  </li>
                  <li>
                    <i className="fa fa-whatsapp"></i> WhatsApp No.: 
                    <strong>  0120 6226666 &nbsp; &nbsp;|</strong>
                  </li>
                  {/* <li>
                    <Link className="nav-link susTab" to="/Sustainability"> Sustainability
                  </Link>
                  </li> */}
                </ul>
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 w-100 ">
                  <li className="nav-item dropdown">
                    <Link className="nav-link" to="/Home">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item dropdown dropdownstat">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      data-toggle="dropdown"
                    >
                      About NPCL
                    </a>
                    <ul className="dropdown-menu dropdown-menu-large">
                      <div className="row">
                        <div className="col-sm-4">
                          <ul>
                            <li className="dropdown-header">
                              NPCL At A Glance
                            </li>
                            <li>
                              <Link
                                to="/company-profile"
                                className="dropdown-item"
                              >
                                Company Profile
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/corporate-policies"
                                className="dropdown-item"
                              >
                                Corporate Policies
                              </Link>
                            </li>
                            <li>
                      <a className="dropdown-item"
                          href="https://iwebapps.noidapower.com:8032/download/Notice of 31st AGM-Website.pdf"
                          target="_blank"  rel="noreferrer"
                        >
                          Notice of 31<sup>st</sup> AGM
                        </a>
                      </li>
                      <li>
                      <a className="dropdown-item"
                          href="https://iwebapps.noidapower.com:8032/download/Notice of 32nd AGM - Website.pdf"
                          target="_blank"  rel="noreferrer"
                        >
                          Notice of 32<sup>nd</sup> AGM
                        </a>
                      </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://iwebapps.noidapower.com:8032/awards_recognition.aspx"
                                target="_blank"  rel="noreferrer"
                              >
                                Award & Recognition
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="col-sm-4 lr_gutter">
                          <ul>
                            <li className="dropdown-header">
                              Our Value Systems
                            </li>
                            <li>
                              <Link
                                to="/vission-mission"
                                className="dropdown-item"
                              >
                                Vision &amp; Mission
                              </Link>
                            </li>
                            {/* <!-- <li><a href="values-beliefs.html" className="dropdown-item">Values & Beliefs</a></li> --> */}
                            <li>
                              <Link
                                to="/rpsg-group-vision-and-core-values"
                                className="dropdown-item"
                              >
                                RPSG Group Vision and Core Values
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-sm-4 lr_gutter">
                          <ul>
                            <li className="dropdown-header">
                              Corporate Citizenship
                            </li>
                            <li>
                              <Link
                                to="/corporate-social-responsibility"
                                className="dropdown-item"
                              >
                                Corporate Social Responsibility
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </ul>
                  </li>

                  <li className="nav-item dropdown dropdownstat">
                    <Link
                      className="nav-link dropdown-toggle"
                      href="#"
                      data-toggle="dropdown"
                    >
                      Regulations
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-large">
                      
                      <div className="row">
                        <div className="col-sm-6">
                          <ul>
                          <li className="dropdown-header">Tariff</li>
                            {/* <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/Tariff%20applicable%20from%204th%20August%202022.pdf"
                                target="_blank" rel="noreferrer"
                                className="dropdown-item"
                              >
                                Tariff applicable from 4th August 2022
                              </a>
                            </li> */}
                             <li>
                              <Link
                                to="/NPCL-Tariff-Petition-ARR-2024-25"
                                className="dropdown-item"
                              >
                               NPCL Tariff Petition for ARR 2024-25
                              </Link>
                            </li>
                            <li>
                        <a href="https://iwebapps.noidapower.com:8032/download/Tariff%20applicable%20from%206th%20June%202023.pdf"
                          target="_blank" rel="noreferrer"  className="dropdown-item" >
                          Tariff applicable from 6th June 2023
                        </a>
                      </li>

                      <li>
                        <a href="https://iwebapps.noidapower.com:8032/download/Tariff Order NPCL FY 2023-24.pdf" target="_blank" rel="noreferrer"
                         className="dropdown-item">
                         NPCL Tariff Order for FY 2023-24 dated 24th May 2023

                        </a>
                      </li>
                            <li>
                              <Link
                                to="/cost-data-book"
                                className="dropdown-item"
                              >
                                Cost Data Book
                              </Link>
                            </li>

                            <li>
                              <Link
                                to="/Fuel-and-Power-Purchase-Cost-Adjustment"
                                className="dropdown-item"
                              >
                               Fuel Surcharge (FPPCA)
                              </Link>
                            </li>

                            {/* <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/ARR_FY2023.aspx"
                                target="_blank" rel="noreferrer"
                                className="dropdown-item"
                              >
                                Petition No. 1919 of 2022 dated 30th November, 2022 of the Company for approval of True-up for FY 2021-22, APR for FY 2022-23 and ARR for FY 2023-24
                              </a>
                            </li> */}

                            {/* <li>
                            <Link
                                to="/EnergyAudit"
                                className="dropdown-item"
                              >
                                Energy Audit & Accounting
                              </Link>
                            </li> */}
                           
                          </ul>
                        </div>
                        <div className="col-sm-6 lr_gutter">
                          <ul>
                          <li class="dropdown-header"> &nbsp; </li>
                           
                            <li>
                              <a
                                href="https://www.uperc.org/Notified_User.aspx"
                                target="_blank" rel="noreferrer"
                                className="dropdown-item"
                              >
                                UPERC Regulations   
                              </a>
                            </li>

                            <li>
                              <a
                                href="https://cea.nic.in/regulations-category/notified-regulations/?"
                                target="_blank" rel="noreferrer"
                                className="dropdown-item"
                              >
                               CEA Regulations
                              </a>
                            </li>
                            {/* <li>
                              <Link
                                to="/tarrif-schedule"
                                className="dropdown-item"
                              >
                                Tariff Schedule
                              </Link>
                            </li> */}
                            <li>
                              <Link
                                to="/EnergyAudit"
                                className="dropdown-item"
                              >
                                Energy Audit and Accounting
                              </Link>
                            </li>
                            <li>
                              <a
                                href="/up-electricity-supply-code"
                                className="dropdown-item"
                              >
                                UP Electricity Supply Code
                              </a>
                            </li>
                          </ul>
                        </div>
                        
                      </div>
                    </ul>
                  </li>
                  <li className="nav-item dropdown dropdownstat">
                    <a
                      className="nav-link border-end-0  dropdown-toggle"
                      href="#" 
                      data-toggle="dropdown" 
                    >
                      Procurement
                    </a>
                    <ul className="dropdown-menu dropdown-menu-smls">
                      <li className="dropdown-header">Procurement</li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/online-vendor-registration"
                        >
                          Online Vendor Registration
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/contact-for-eprocurement"
                        >
                          Contact for e-Procuremen
                        </Link>
                      </li>
                      <li>
                        <a className="dropdown-item" href="https://iwebapps.noidapower.com:8032/download/Sustainable_Procurement_Policy.pdf"
                                target="_blank" rel="noreferrer">
                          Sustainable Procurement Policy-NPCL
                        </a>
                      </li>

                      <li>
                      <a className="dropdown-item"
                                href="https://iwebapps.noidapower.com:8032/powerProcAddr.aspx"
                                target="_blank" rel="noreferrer"
                              >
                          Contact for Power-Procurement
                        </a>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/tenders">
                          Tenders
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item dropdown dropdownstat">
                    <a
                      className="nav-link border-end-0  dropdown-toggle"
                      href="#"
                      data-toggle="dropdown"
                    >
                      Careers
                    </a>
                    <ul className="dropdown-menu dropdown-menu-smls">
                      <li className="dropdown-header">Life @ NPCL</li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="https://iwebapps.noidapower.com:8032/current_opening.aspx"
                          target="_blank" rel="noreferrer"
                        >
                          Current Openings
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="https://iwebapps.noidapower.com:8032/cv_upload.aspx"
                          target="_blank" rel="noreferrer"
                        >
                          Upload Your CV
                        </a>
                      </li>
                    </ul>
                  </li>
                  {/* <li className="nav-item dropdown">
                    <Link className="nav-link" to="/Sustainability">
                    Sustainability
                        </Link>
                  </li> */}
                  <li className="nav-item lastmenu">
                    <Link
                      className="nav-link border-end-0"
                      to="/consumer-portal"
                    >
                      Consumer Portal
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-large largds">
                      <ul className="nav nav-tabs nav_tbds">
                        <li className="nav-item">
                          <Link
                            to="#consumerservices"
                            className="nav-link active"
                            data-toggle="tab"
                          >
                            Consumer Services
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/knowledge-center"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Knowledge Center
                          </Link>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#consumeroffer"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Consumer offers
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content tab_main">
                        <div
                          className="tab-pane fade show active"
                          id="consumerservices"
                        >
                          <li className="nav-item dropdown lastmenu lastsub">
                            <ul className="dropdown-menu dropdown-menu-large innermenus">
                              <div className="row">
                                <div className="col-sm-3">
                                  <ul>
                                    <li className="dropdown-header">
                                      Online Request
                                    </li>
                                    <li>
                                      <a
                                        href="https://www.noidapower.com/online-applications"
                                        className="dropdown-item"
                                      >
                                        Application Form
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="https://www.noidapower.com/Document-Checklist"
                                        className="dropdown-item"
                                      >
                                        Document Checklist
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="https://iwebapps.noidapower.com:8032/sampledocument.aspx"
                                        className="dropdown-item"  target="_blank" rel="noreferrer"
                                      >
                                        Sample Document
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="https://iwebapps.noidapower.com:8032/Authenticate/login.aspx"
                                        className="dropdown-item"  target="_blank" rel="noreferrer"
                                      >
                                        Apply for Isolation
                                      </a>
                                    </li>
                  
                                    <li>
                                      <a
                                        href="https://iwebapps.noidapower.com:8032/self_meter_reading.aspx"
                                        className="dropdown-item"  target="_blank" rel="noreferrer"
                                      >
                                        Self Meter Reading
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="https://iwebapps.noidapower.com:8032/MTRCHPMT/MeterTesting_Reg.aspx"
                                        className="dropdown-item" target="_blank" rel="noreferrer"
                                      >
                                          Meter Testing
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-sm-3 lr_gutter">
                                  <ul>
                                    <li className="dropdown-header">
                                      Queries/ Complaints
                                    </li>

                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="https://www.noidapower.com/callcenter"
                                        title=""
                                      >
                                        Touch Points
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="https://iwebapps.noidapower.com:8032/complaint_new.aspx"
                                        title="" target="_blank" rel="noreferrer"
                                      >
                                        Online Complaints
                                      </a>
                                    </li>

                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="https://iwebapps.noidapower.com:8032/online_payment_Receipt.aspx"
                                        title="" target="_blank" rel="noreferrer"
                                      >
                                        Last Payment Details
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="https://iwebapps.noidapower.com:8032/SMS_based_Services.aspx"
                                        title=""  target="_blank" rel="noreferrer"
                                      >
                                        SMS Based Services
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="https://www.noidapower.com/whatsapp_based_services"
                                        title=""
                                      >
                                        WhatsApp Services
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="https://iwebapps.noidapower.com:8032/Claim_Compensation.aspx"
                                        title="" target="_blank" rel="noreferrer"
                                      >
                                        Compensation (SOP'19)
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="https://iwebapps.noidapower.com:8032/QMS_RealTime.aspx"
                                        title="" target="_blank" rel="noreferrer"
                                      >
                                        Customer Care Office queue status
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="https://www.noidapower.com/rooftop-solar"
                                        title="" 
                                      >
                                        Solar Roof Top Net Metering
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-sm-3 lr_gutter">
                                  <ul>
                                    <li className="dropdown-header">
                                      Information Center
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="https://iwebapps.noidapower.com:8032/consumer_Contact.aspx"
                                        title="" target="_blank" rel="noreferrer"
                                      >
                                        Customer Contact Program
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="https://iwebapps.noidapower.com:8032/download/Consumer_HandbookNEW.pdf"
                                        title="" target="_blank" rel="noreferrer"
                                      >
                                        Consumer Handbook
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="https://www.noidapower.com/grievence"
                                        title=""
                                      >
                                        Manual of practice for handling customer
                                        complaints
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="https://iwebapps.noidapower.com:8032/download/SOP_GUIDELINES.pdf"
                                        title="" target="_blank" rel="noreferrer"
                                      >
                                        SOP'19 Guidelines
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="https://iwebapps.noidapower.com:8032/calculate_energy.aspx"
                                        title=""  target="_blank" rel="noreferrer"
                                      >
                                        Calculate Your Load
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="https://iwebapps.noidapower.com:8032/MyFolder/Discom_Dictionary.pdf"
                                        title="" target="_blank" rel="noreferrer"
                                      >
                                        Discom Dictionary
                                      </a>
                                    </li>
                                    <li>
                                    <Link to="/third-party-meter-testing" className="nav-link" data-toggle="tab">
                                              Know Your Pre - Paid Meter
                                      </Link>
                
                                    </li>
                                    <li>
                                  
                                      <Link to="/KnowYourPrepaidMeter" className="nav-link" data-toggle="tab">
                                              Know Your Pre - Paid Meter
                                      </Link>
                                     
                                    </li>
                                    <li>
                                  
                                      <Link to="/single-point-to-multipont-conversion" className="nav-link" data-toggle="tab">
                                        Single Point to Multipoint Convertion
                                      </Link>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="https://iwebapps.noidapower.com:8032/holiday.aspx"
                                        title="" target="_blank" rel="noreferrer"
                                      >
                                        Holiday Calendar
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="https://iwebapps.noidapower.com:8032/NPCL_creatives.aspx"
                                        title=""  target="_blank" rel="noreferrer"
                                      >

                                        Consumer Awareness
                                     </a>
                                 
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="https://www.youtube.com/@noidapowercompanylimited3489" target="_blank"
                                        title="" rel="noreferrer"
                                      >
                                        Youtube Channel
                                      </a>
                                    </li>
                                    <li>
                                    <Link to="/faq" className="nav-link" data-toggle="tab">
                                        FAQ
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-sm-3 lr_gutter">
                                  <ul>
                                    <li className="dropdown-header">
                                      Feedback
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="https://iwebapps.noidapower.com:8032/Customer_Feedback_4.aspx"
                                        title="" target="_blank" rel="noreferrer"
                                      >
                                        Feedback
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="https://iwebapps.noidapower.com:8032/Customer_Testimonial.aspx"
                                        title="" target="_blank" rel="noreferrer"
                                      >
                                        Consumer Testimonials
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </ul>
                          </li>
                        </div>
                        <div className="tab-pane fade" id="knowledgecenter">
                          <li className="nav-item dropdown lastmenu lastsub">
                            <ul className="dropdown-menu dropdown-menu-large innermenus">
                              <div className="row">
                                <div className="col-sm-12">
                                  <ul>
                                    <li className="dropdown-header">
                                      Safety & Conservation
                                    </li>
                                    <li>
                                    <Link to="/safety-first" className="dropdown-item" data-toggle="tab">
                                    Safety
                                    </Link> 
                                    </li>
                                    <li>
                                    <Link to="/dosndnts" className="dropdown-item" data-toggle="tab">
                                    Dos & Don'ts
                                    </Link> 
                                      
                                    </li>
                                    <li>
                                    <Link to="/easy-ways-to-lower-your-bill" className="dropdown-item" data-toggle="tab">
                                    Easy Ways to Lower Your Bill
                                    </Link> 
                      
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </ul>
                          </li>
                        </div>
                        <div className="tab-pane fade" id="consumeroffer">
                          <li className="nav-item dropdown lastmenu lastsub">
                            <ul className="dropdown-menu dropdown-menu-large innermenus">
                              <div className="row">
                                <div className="col-sm-12">
                                  <ul>
                                    <li>
                                    <Link to="/rebate" className="dropdown-item" data-toggle="tab">
                                    Rebates
                                    </Link> 
                            
                                    </li>
           
                                   
                                  </ul>
                                </div>
                              </div>
                            </ul>
                          </li>
                        </div>
                      </div>
                    </ul>
                  </li>
                  <li className="loginclick">
                    <a
                      href="https://iwebapps.noidapower.com:8032/Authenticate/login.aspx"
                      target="_blank" rel="noreferrer"
                    >
                      Sign In/ Register
                    </a>
                  </li>

                  {/* <li className="searchtop">
                    <a href="#" className="searchopen" onClick={openFuncation}>
                      <i className="fa fa-search"></i>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
      <div className="search-wrap" id="search-wrap">
        <div className="search-middle">
          <div className="close-btn" onClick={closeFuncation}>
            X
          </div>
          <div className="container text-center">
            <input
              type="text"
              className="st-default-search-input"
              placeholder="Search..."
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
