import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const Grievence = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/small/grievance-redressal.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home </a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" className="active">
                      Complaint Management
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-12 bg_cols">
                  <div className="form_div">
                    <div className="tab-content tab_main">
                      <div className="tab-pane fade show active" id="login1">
                        <div className="main_wrappersd">
                          <div className="row">
                            <div className="col-md-7">
                              <div className="companysection">
                                <legend>COMPLAINT MANAGEMENT: THREE TIER GRIEVANCE REDRESSAL STRUCTURE
                                 शिकायत प्रंबधन : तीन स्तरीय शिकायत निवारण संरचना</legend><hr/>
                              <b>TIER – I: NPCL Complaint escalation & Redressal structure</b>
                              <p>In case of any concern/ request you may visit our Customer Care Office and meet the following official with complete detail:</p>
                              <div className="row">
                             <div className="col-md-12">
                             <ul style={{listStyle:"none"}}>
                             <li><b>Level 1:</b> Customer Relation Officer</li>
                             <li><b>Level 2:</b>Customer Relation Managers</li>
                             <li><b>Level 3:</b>Concern Departmental Heads with prior appointment</li>
                             </ul>
                             </div>
                             </div>
                             <b>TIER-II  :</b>
                             <p><b>Consumer Grievance Redressal Forum (CGRF):</b> A forum is constituted under the Chairmanship of a retired Judge and two other members wherein the Consumers of electricity can file their complaints for the redressal of their grievances related to deficiency/ error in bill or services or any non- compliance of standards & directions issued by the Uttar Pradesh Electricity Regulatory Commission in this regard.</p>
                             <p><b>Address of the forum:</b> Consumer Grievance Redressal Forum (CGRF), 33/11KV Electric Sub Station, Block-D, Opposite Ryan International School, Sector Alpha-1, Greater Noida, Uttar Pradesh- 201310</p>
                             <b>Phone No. of the Secretary of the Forum – 9911510997</b>
                             <p><b>Public Hearing by the Forum:</b> A monthly camp is organized on the 17th of every month (in case, 17th being Saturday/holiday, after holiday) at CGRF, where the employees of NPCL are also present.</p>
                             <b>TIER – III:</b>
                             <p><b>Electricity Ombudsman:</b>An Appeal can be filled before the Electricity Ombudsman of the State on not being satisfied by the order of the CGRF.</p>
                             <p><b>Address of the Office of Electricity Ombudsman:</b> Niyamak Bhawan, Vibhuti Khand, Gomti Nagar, Lucknow, Uttar Pradesh – 226010 <b>Phone No.</b> – 0522- 2720856, <b>Fax-</b> 0522-2720857,
                              <b>Email:</b> eo-up@uperc.org</p>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="companyimg">
                                <img src="assets/images/tier3.jpg" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Grievence;
